<template>
  <div v-if="items.length" class="select" :class="{ 'select--focus': show, 'select--active': active }">
    <Button
      v-if="!isShowMore"
      :ref="'button'"
      class="select__button"
      cta="secondary"
      :class="{ active: buttonText !== null || active }"
      @clicked="show = show ? false : true"
    >
      <span class="capitalize">{{ buttonText || activeItem.label }}</span>

      <Icon class="ml-2" :width="10" icon="arrow" />
    </Button>

    <Button v-if="isShowMore" :ref="'button'" class="select__dots" @clicked="show = show ? false : true">
      <div class="dot" />
    </Button>

    <div class="shadow-2xl select__list-container" :style="positionStyle">
      <ul v-if="show" v-closable="{ handler: 'hideList', exclude: ['button'] }" class="select__list">
        <li v-for="(item, i) in list" :key="i" class="select__item" @click="selectItem(item)">
          <Button
            :id="dropdownId && item.id ? `dropdown-${dropdownId ? dropdownId + '-' : ''}${item.id}` : ''"
            :url="item.url ? item.url : ''"
            class="capitalize"
          >
            <Icon v-if="item.type === 'icon'" :icon="item.label" width="16px" height="16px" class="mr-2" />

            {{ item.label }}
          </Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Icon } from "cavea-design-system";

export default {
  name: "Dropdown",

  components: {
    Button: () => import("@/components/Button"),
    Icon,
  },

  props: {
    items: {
      type: Array,
      default: null,
    },

    border: {
      type: [Boolean, String],
      default: true,
    },

    position: {
      type: Array,
      default: null,
    },

    prop: {
      type: [Object, String, Number, Array],
      default: null,
    },

    index: {
      type: Number,
      default: null,
    },

    isShowMore: {
      type: Boolean,
    },

    button: {
      type: [Object, String, Number, Array],
      default: null,
    },

    currentItem: {
      type: String,
      default: null,
    },

    buttonText: {
      type: String,
      default: null,
    },

    dropdownId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      show: false,
      activeItemIndex: 0,
      active: false,
    };
  },

  computed: {
    positionStyle() {
      let style = "";

      if (this.position) {
        if (this.position?.indexOf("right") > -1) {
          style += "left: initial; right: 0";
        }
      }

      return style;
    },

    list() {
      return this.items;
    },

    activeItem() {
      return this.items[this.activeItemIndex];
    },
  },

  mounted() {
    let activeItemIndex = this.items.findIndex((x) => x?.selected === true);

    if (this.currentItem) {
      activeItemIndex = this.items.findIndex((x) => x?.id === this.currentItem);
    }

    if (activeItemIndex === -1) {
      this.activeItemIndex = 0;
    } else {
      this.activeItemIndex = activeItemIndex;
    }

    if (this.items[activeItemIndex]) {
      this.active = this.items[activeItemIndex]?.id !== "";
    }
  },

  methods: {
    hideList() {
      this.show = false;
    },

    selectItem(item) {
      this.show = false;

      this.items.forEach((x) => {
        x.selected = false;
      });

      this.activeItemIndex = this.items.indexOf(item);
      this.items[this.activeItemIndex].selected = true;

      if (item.id !== "") {
        this.active = true;
      } else {
        this.active = false;
      }

      this.$emit("change", {
        prop: this.prop,
        index: this.index,
        item: { id: item?.id, label: item?.label, index: item?.index },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/globals";
@import "../styles/colors";

.select {
  display: flex;
  height: 2.5rem;
  transition: var(--transition-in);
  place-items: center;

  > .button {
    text-align: left;
  }
}

.select__dots {
  display: flex;
  height: 1.25rem;

  &:hover,
  &:focus {
    transform: rotate(90deg);

    .dot,
    &::before,
    &::after {
      background-color: color(blue, light);
    }
  }

  .dot,
  &::before,
  &::after {
    flex-shrink: 0;
    width: 0.25rem;
    height: 0.25rem;
    display: block;
    background-color: #a5acc5;
    border-radius: 50%;
  }

  .dot {
    margin: 0 0.25rem;
  }

  &::before,
  &::after {
    content: "";
  }
}

.select__list {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 1rem 0;
  background-color: color(white, base);

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    margin-right: -2rem;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    transition: var(--transition-in);
  }
}

.select__list-container {
  display: flex;
  top: calc(100% + 1rem);
  border-radius: 3px;
  overflow: hidden;
  z-index: 7000;
  box-shadow: $box-shadow;
  left: 0;
  position: absolute;
  max-height: 300px;
}

.select__item {
  > .button {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    padding: 1rem 1.5rem;

    &:hover {
      color: color(black, base);
    }
  }
}
</style>
